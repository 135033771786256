<template>
  <div>
    <div class="base_head">
      <div class="flex-center navs" :class="{ 'fixedBar': isFixed }" :style="style">
        <div class="flexs" id="boxFixed">
          <div>
            <h1 class="logo-h1">云百科技</h1>
            <router-link to="/home"><img class="logoImg" src="@img/logo.png" alt="云百科技" @click="getSqlCheck" /></router-link>
          </div>

          <div class="pos_r">
            <router-link  v-for="(item, index) in list"
                :key="index" :to="item.path" style="font-size: 16px;">
              <div
                class="navitem"
                @click="select(index)"
                @mouseenter="mouseenter(index)"
                @mouseleave="mouseleave"
                :class="[activeIndex === item.index ? 'is-active' : '', mouseIndex === index ? 'mou-active' : '']"
              >
              <span style="font-size: 16px;">{{ item.name }}</span>
            
            </div>
          </router-link>
          </div>
        </div>
      </div>
      
      <!-- 轮播图 -->
      <el-carousel class arrow="never" :interval="4000" v-if="isBanners" :indicator-position="indicator">
        <el-carousel-item v-for="(item, index) in bannerDatas" :key="index" class="img_box">
          <div :class="item.banner">
            <el-row class="container margin-banner">
              <el-col :span="14" style="text-align: left;" :style="{color:color}">
                <h3 class="LOT-title">{{ item.title }}</h3>
                <p class="LOT-in" >{{ item.content }}</p>
                <!-- <p class="LOT-in" :style="{color:textColor}">{{ item.content }}</p> -->
                <button class="apply-button" @click="isShow = true">{{ item.button }}</button>
              </el-col>
              <el-col :span="10" style="text-align: right;">
                <img :src="item.img" class="dynamic-img" v-if="isGif" />
              </el-col>
            </el-row>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <solution class="solutions" v-if="solutionShow" @mouseleave="hides" @transfer="getFlag"></solution> -->
    </div>
    <!-- <div class="sideMenu" @click="messageHandle"> -->

    <div class="hoverMsg" @mouseleave=" messageShow = false">
      <div class="message-box" :class="messageShow === true ? '' : 'visibility'">
        <div class="online" style="cursor:pointer;" @click="isShow = true">
          <div class="message-div">
            <img src="@img/about/ic_zf_ly.png" />
            <h3>在线留言</h3>
          </div>
          <p>专属客户经理解答，提供专业的方案</p>
        </div>
        <div class="lineB"></div>
        <div class="online">
          <div class="message-div">
            <img src="@img/about/ic_zf_kf.png" />
            <h3>客服热线</h3>
          </div>
          <p>0769-89008900</p>
        </div>
      </div>
      <div class="sideMenu" @mouseover="messageShow = true" >
        <img src="@img/about/ic_xfc_lx.png" />
        <span>联系我们</span>
      </div>
    </div>
    <scroll-top class="backTop"></scroll-top>
    <plan-dialog :isShow="isShow" v-if="isShow" @closeTop="closeTop"></plan-dialog>
    
  </div>
</template>
<script>
import planDialog from "@components/planDialog.vue"

import solution from "@components/solution.vue"
import mixin from '@/mixin/index.js'
export default {
  mixins: [mixin],
  components: {
    planDialog,
    solution,
  },
  props: {
    bannerData: {
      type: [Array],
      default: () => []
    },
    active: {
      type: [Number, String],
      default: 0
    },
    isBanner: {
      type: [Boolean],
      default: true
    },
    isGif: {
      type: [Boolean],
      default: true
    },
    bannerLists: {
      type: [Array],
      default: () => []
    },
    color:{
      type:[String],
      default:'black'
    },
	textColor:{
		type:[String],
		default:'black'
	},
    indicator:{
      type:[String],
      default:''
    }
  },
  data() {
    return {
      dynamicImg: require('@assets/dynamic_banner.gif'),
      count: 0,
      messageShow: false,
      // slideShows:false,
      isShow: false,
      activeIndex1: null,
      bannerDatas: this.bannerData,
      activeIndex: this.active,
      mouseIndex: this.active,
      isBanners: this.isBanner,
      isGifs: this.isGif,
      imgHeight: "",
      solutionShow:false,
      list: [
        {
          path: '/home',
          name: '首页',
          index: 0
        },
        {
          path: '/serve',
          name: '物联网',
          index: 1
        },
        // {
        //   path: '/indusAuto',
        //   name: '工业数字化',
        //   index: 2
        // },
         {
          path: '/info',
          name: '招标公告',
          index: 4
         },
        {
          path: '/aboutUs',
          name: '关于我们',
          index: 3
        },
        // {
        //   path: '/solution',
        //   name: '解决方案',
        //   index: '4'
        // },
        // {
        //   path: '/info',
        //   name: '信息中心',
        //   index: '4'
        // },
      ],

      serviceList: [
        {
          img: require('@img/home/ic_solution0.png'),
          title: '定制专属方案'
        },
        {
          img: require('@img/home/ic_solution1.png'),
          title: '免费备案'
        },
        {
          img: require('@img/home/ic_solution2.png'),
          title: '1个工作日内排除故障'
        },
        {
          img: require('@img/home/ic_solution3.png'),
          title: '1V1专业客服'
        },
        {
          img: require('@img/home/ic_solution4.png'),
          title: '7×24小时服务'
        },
      ],
      bannerList: this.bannerLists,
      // color:'black',
      // color:'white',
      isFixed: false,
      style: {},
      opacity: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      let offsetTop = document.querySelector('#boxFixed').offsetTop;  // 要滚动到顶部吸附的元素的偏移量
      // this.style = scrollTop > offsetTop + 100 ? {background: `#cccccc`} : {background: `#ffffff`}
      this.isFixed = scrollTop > offsetTop + 100 ? true : false;
    },
    messageHandle() {
      this.messageShow = !this.messageShow
    },
    closeTop() {
      this.isShow = false;
    },
    select(index) {
      let paths = this.list[index].path;
      // if(paths == '/solution'){
      //   return false
      // }
      // this.$router.push({ path: paths })
    },
    mouseenter(index) {
      this.mouseIndex = index
      console.log('activeIndex--',this.activeIndex)
      // if(index == 3){
      //   this.solutionShow = true
      // }else{
      //   this.solutionShow = false
      // }
    },
    // getFlag(flag){
    //   console.log(flag)
    //   this.solutionShow = flag
    // },
    // hides(){
    //   this.solutionShow = false;
    // },
    mouseleave() {
      this.mouseIndex = ''
    },
    mouseenter1(index) {
      this.activeIndex1 = index
    },
    mouseleave1() {
      this.activeIndex1 = ''
    },
    goBanPath(path) {
      if (!path) return
      this.$router.push({ path })
    },
    getSqlCheck() {
      this.$router.push({ path: '/home' })
      if (this.count === 0) {
        this.startTime = new Date();
        this.lastTime = this.startTime;
      } else if (this.count >= 1) {
        let nextTime = new Date();
        if (nextTime - this.startTime >= 1500) {
          // 超过五秒重置
          this.startTime = nextTime;
          this.lastTime = nextTime;
          this.count = 0;
        } else {
          this.lastTime = nextTime;
        }
        if (this.count === 5) {
          // 大于三次重置
          this.sqlCheck(true);
          this.startTime = nextTime;
          this.lastTime = nextTime;
          this.count = 0;
        }
      }
      this.count++;
      // console.log(this.count);
    },
    sqlCheck(flag) {
      console.log(flag)
      this.$router.push({ path: '/test' })
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll); // 离开页面 关闭监听 不然会报错
  }
};
</script>

<style lang="scss">
// .el-tabs__nav-wrap::after {
//   height: 1px;
// }
// .sideMenu:hover .message-box{
//   background: #f00;
//   display: block;
// }
.backTop{
  bottom: 100px !important;
  right: 24px !important;
  width: 44px;
  height: 44px;
  background: linear-gradient(180deg, #f2f5fa, #fefefe);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 2px 6px 10px #59585814; 
  i{font-size: 24px; }
  :deep(.el-icon-caret-top){font-size: 24px;}
}
.solutions{
  position: fixed;
  width: 100%;
  top: 80px;
  z-index: 99;
}
//头部
.base_head {
  z-index: 99;
  position: relative;
  height: auto;
  //导航栏起始
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .flexs {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .logo-h1 {
    display: none;
  }
  .navs {
    background: #ffffff;
    backdrop-filter: blur(16px);
    position: absolute;
    z-index: 99;
    width: 100%;
  }
  .logoImg {
    width: 132px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
  }
  .fixedBar {
    width: 100%;
    color: #fff;
    z-index: 100;
    position: fixed;
    background: #ffffff;
    top: 0;
    box-shadow: 2px 6px 10px #59585814;
  }

  .navitem {
    margin-left: 48px;
    // height: 60px;
    text-align: center;
    line-height: 64px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #4d4d4d;
    cursor: pointer;
    .router-link{
      span {
        font-size: 16px !important;
      }
    } 
  }
  ::v-deep .navitem.is-active {
    font-size: 16px !important;
    font-weight: 700;
    color: #1e77ec !important;
    position: relative;
  }
  .navitem.is-active {
    font-size: 16px !important;
    font-weight: 700;
    color: #1e77ec !important;
    position: relative;
  }
  .navitem.mou-active {
    font-size: 16px;
    font-weight: 700;
    color: #1e77ec !important;
    position: relative;
  }
  //导航栏结束

  //轮播图起始
  .el-carousel {
    height: 544px;
    .el-carousel__container {
      height: 544px;
    }
  }
  .margin-banner {
    margin-top: 80px !important;
  }
  //申请试用按钮
  .apply-button {
    margin-top: 90px;
    width: 136px;
    height: 44px;
    background: #0063E6;
    border-radius: 4px;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  .el-carousel {
    height: 550px;
    .el-carousel__container {
      height: 544px;
    }
  }

  .el-carousel__indicators {
    bottom: 102px;
    li {
      width: 47px;
      height: 3px;
      opacity: 0.38;
      background: #ffffff;
      border-radius: 2px;
      padding: 0;
      margin: 0 4px;
      background: #2b4fc7;
      border-radius: 1px;
      .el-carousel__button {
        width: auto;
        height: auto;
      }
    }
    .el-carousel__indicator.is-active {
      opacity: 1;
      background: #1e77ec;
      button {
        width: auto;
        height: auto;
      }
    }
  }

  .img_box {
    width: 100%;
    .banner_item {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 420px;
      margin: 0;
      height: 420px;
    }
  }
  //轮播图样式
  .banners {
    background: url("../../public/img/banner_iot_a_bg.png") no-repeat;
    //  background: linear-gradient(90deg,#f7f9fc, #f9f9f9);
    position: relative;
    background-size: 100% 100%;
    height: 500px;
    position: absolute;
    width: 100%;
    .dynamic-img {
      width: 420px;
      text-align: right;
      margin-top: 5px;
    }
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 99px;
      text-align: left;
    }
  }
  .banners1 {
    background: radial-gradient(#daf3fb, #c2dee6);
    height: 500px;
    position: absolute;
    width: 100%;
    .dynamic-img {
      width: 420px;
      text-align: right;
      margin-top: -40px;
    }
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 99px;
      text-align: left;
    }
  }
  .banners2 {
    background: url("../../public/img/about/banner_gywm.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 40px;
      text-align: left;
    }
  }
  .banners3 {
    background: url("../../public/img/solution/img_jjfa.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
  }
  .home_bg1{
    background: url("../../public/img/home/home_bg1.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
    img{
      margin-top: 40px;
    }
  }
  .indusAuto_bg{
    background: url("../../public/img/indusAuto/indusAuto_bg.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
    img{
      margin-top: 40px;
    }
  }
  .indusAuto_bg1{
    background: url("../../public/img/indusAuto/indusAuto_bg.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
    img{
      display: none;
    }
  }
  .about_bg{
    background: url("../../public/img/about/about_bg.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
  }
  .serve_bg{
    background: url("../../public/img/about/serve_bg.png");
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    .LOT-title {
      font-size: 40px;
      font-weight: 400;
      margin-top: 48px;
      text-align: left;
    }
    img{
      width: 400px;

    }
  }
  .LOT-in {
    margin-top: 16px;
	color: #6E747A;
    width: 565px;
    height: 52px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 28px;
  }
  //轮播图结束

  //五大服务
  .service {
    z-index: 99;
    background-color: #fff;
    width: 1200px;
    height: 88px;
    line-height: 88px;
    margin: 0 auto;
    bottom: 0px;
    left: 50%;
    margin-left: -600px;
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 14px 0px #e4e9f2;
    ul {
      display: flex;
      justify-content: space-around;
      img {
        vertical-align: middle;
      }
      span {
        margin-left: 8px;
      }
      li {
        width: 100%;
        position: relative;
        .lineB {
          display: none;
          opacity: 1;
          position: absolute;
          background: #1e77ec;
          bottom: 0px;
          width: 100%;
          height: 2px;
        }
      }
      li.partnerActive {
        transform: scale(1.3) !important;
        z-index: 11;
      }
    }
  }
}
.hoverMsg{
  position: fixed;
  bottom: 160px;
  right: 24px;
  z-index: 999;
  display: flex;
  align-items: flex-end;
}
.visibility{
  visibility: hidden;
}
.sideMenu:hover,.message-box:hover{
  box-shadow: 8px 8px 24px 0px rgb(0 55 128 / 16%);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
}

//联系我们
.sideMenu {
  margin-top: 10px;
  width: 44px;
  bottom: 160px;
  height: 118px;
  background: linear-gradient(180deg, #f2f5fa, #fefefe);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 2px 6px 10px #59585814;
  // position: fixed;
  right: 24px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
  span {
    width: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #0063e6;
    line-height: 18px;
  }
}
//在线留言
.message-box {
  margin-top: 10px;
  // display: none;
  width: 293px;
  height: 145px;
  background: linear-gradient(180deg, #f2f5fa, #fefefe);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 14px 0px #e4e9f2;
  position: relative;
  // right: 80px;
  z-index: 999;
  text-align: left;
  
  bottom: -27px;
  margin-right: 20px;
  .lineB {
    // background: #98a3af;
    // height: 1px;
    // width: 290px;
    background: #dcdee1;
    height: 1px;
    width: 100%;
    // margin-left: 41px;
  }
  .online {
    padding: 16px 0 15px 13px;
    h3 {
      display: inline-block;
      margin-left: 13px;
      font-size: 16px;
      font-weight: 400;
    }
    img {
      margin-left: 5px;
    }
    p {
      color: #98a3af;
      margin-left: 39px;
    }
  }
}

//页面的公共样式
.tab-padding {
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba(155, 178, 212, 0.23);
  .el-tabs {
    margin: 12px 24px 24px;
  }
}
.main-bottom {
  padding-bottom: 78px !important;
}

.bg-box {
  background: linear-gradient(180deg, #f3f5f8, #fefefe);
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 14px 0px #f0f3f8;
}

.el-row {
  padding: 10px 0 40px;
}
//tab选项卡
.el-tabs__item.is-active {
  font-weight: 700;
  text-align: center;
  color: #161719;
}
//tab选项卡的下划线颜色
.el-tabs__active-bar {
  background: linear-gradient(90deg, #0063e6, #9cc6fe);
}
.el-tabs__header {
  margin-bottom: 30px;
}
:deep(.base_head .el-carousel__indicators li){
  width: 32px;
}
.base_head .el-carousel__indicators li{
  width: 32px;
}
.is-active {
    font-size: 16px;
    font-weight: 700;
    color: #1e77ec !important;
    position: relative;
  }
</style>
