<template>
  <div class="article">
    <el-header>
      <v-head :active="4" :isBanner="false"></v-head>
    </el-header>
    <div class="details">
      <div class="container">
        <div class="nsv">
          <!-- <img
            src="@img/ic_new_fh@2x.png"
            alt
            @click="goHome"
            title="回到招标公告"
          />
          <span>你当前位置：</span> -->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/info' }"
              >招标公告</el-breadcrumb-item
            >
            <el-breadcrumb-item>
              <a href="">信息详情</a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content" v-if="showIndex == 0">
          <h1>
            《云百科技基于多源信息的异常监测技术与产品项目硬件采购》招标公告
          </h1>
          <h5 style="text-align: center">（项目编号： YB-2023-1117）</h5>
          <div class="line"></div>
          <p style="text-indent: 2em">
            广东云百科技有限公司对基于多源信息的异常监测技术与产品项目硬件采购进行公开招标，详情参照本项目招标文件。欢迎符合条件的合格投标人参加投标，有关事项如下：
          </p>
          <div class="section">
            <h3>一、项目概况：</h3>
            <div>
              <p>项目编号：YB-2023-1117</p>
              <p>项目名称：广东云百科技有限公司基于多源信息的异常监测技术与产品项目硬件采购</p>
              <p>预算金额：200万元（人民币） 招标单位：广东云百科技有限公司</p>
            </div>
          </div>
          <div class="section">
            <h3>二、采购需求：</h3>
            <p>
              广东云百科技有限公司此项目为基于多源信息的异常监测技术与产品项目部分硬件设备采购项目。
            </p>
          </div>
          <div class="section">
            <h3>三、资质要求：</h3>
            <div>
              <p>1、投标人须为在中华人民共和国境内登记注册的具有独立承担民事责任能力的法人及其他主体。在工商、法律、财务和税务等方面独立运作的合法企业；</p>
              <p>2、报价单位报价物资必须在营业执照经营范围内；</p>
              <p>3、本项目不接受联合体投标。</p>
            </div>
          </div>
          <div class="section">
            <h3>四、参与方式：</h3>
            <p>
              参与投标单位必须先提供加盖公章的营业执照及相关资质证明文件进行报名(线下递交投标资料/线上邮箱投递均可：luzhihua@yunbaitech.cn),经我司相关人员审核通过后即可获得详细的招标文件，未经报名或报名审核不通过的单位所投递的报价文件视为无效；
              投标文件的包裹表面应注明参与单位及参与询价项目名称(如有快递外包裹，应在快递外包裹上注明)，并邮寄或现场送达至我司；
              邮寄地址:广东省东莞市南城街道宏七路26号中熙时代大厦18楼1810；
              收件人：卢志华13412807891。
            </p>
          </div>
          <div class="section">
            <h3>五、提交投标文件截止时间、方式、开标时间和地点：</h3>
            <div>
              <p>1、报名及获取文件开始时间:2023年11月15日；</p>
              <p>2、报名及获取文件截止时间:2023年11月23日；</p>
              <p>3、开标时间：2023年11月30日09:00（北京时间）；</p>
              <p>4、文件递交截止时间:2023年11月24日18:00前（北京时间）；</p>
              <p>5、开标地点：广东省东莞市南城区宏七路26号中熙时代大厦18楼；</p>
              <p>6、商务联系人及联系方式:卢志华0769-89008900；</p>
              <p>7、技术咨询人及联系方式:林晓烁0769-89008900；</p>
              <p>8、逾期送达或者未送达指定地点的投标文件，询价单位不予受理。</p>
            </div>
          </div>
          <div class="section">
            <h3>六、其他说明：</h3>
            <p>
              本项目经评审有效的投标单位不足3名时为招标失败，招标单位分析招标失败原因，修正招标文件，重新组织招标。
            </p>
            <div class="msg_foot">
              <h6>日期：2023年11月15日</h6>
            </div>
          </div>
        </div>
        <div class="content" v-if="showIndex == 1">
          <h1>《云百科技车联网安全检测评估系统项目硬件采购》招标公告</h1>
          <h5 style="text-align: center">（项目编号： YB-2023-1118）</h5>
          <div class="line"></div>
          <p style="text-indent: 2em">
            广东云百科技有限公司对车联网安全检测评估系统项目硬件采购进行公开招标，详情参照本项目招标文件。欢迎符合条件的合格投标人参加投标，有关事项如下：
          </p>
          <div class="section">
            <h3>一、项目概况：</h3>
            <div>
              <p>项目编号：YB-2023-1118</p>
              <p>项目名称：广东云百科技有限公司车联网安全检测评估系统项目硬件采购</p>
              <p>预算金额：270万元（人民币） 招标单位：广东云百科技有限公司</p>
            </div>
          </div>
          <div class="section">
            <h3>二、采购需求：</h3>
            <p>
              广东云百科技有限公司此项目为车联网安全检测评估系统项目部分硬件设备采购项目。
            </p>
          </div>
          <div class="section">
            <h3>三、资质要求：</h3>
            <div>
              <p>1、投标人须为在中华人民共和国境内登记注册的具有独立承担民事责任能力的法人及其他主体。在工商、法律、财务和税务等方面独立运作的合法企业；</p>
              <p>2、报价单位报价物资必须在营业执照经营范围内；</p>
              <p>3、本项目不接受联合体投标。</p>
            </div>
          </div>
          <div class="section">
            <h3>四、参与方式：</h3>
            <p>
              参与投标单位必须先提供加盖公章的营业执照及相关资质证明文件进行报名(线下递交投标资料/线上邮箱投递均可：luzhihua@yunbaitech.cn),经我司相关人员审核通过后即可获得详细的招标文件，未经报名或报名审核不通过的单位所投递的报价文件视为无效；
              投标文件的包裹表面应注明参与单位及参与询价项目名称(如有快递外包裹，应在快递外包裹上注明)，并邮寄或现场送达至我司；
              邮寄地址:广东省东莞市南城街道宏七路26号中熙时代大厦18楼1810；
              收件人：卢志华13412807891。
            </p>
          </div>
          <div class="section">
            <h3>五、提交投标文件截止时间、方式、开标时间和地点：</h3>
            <div>
              <p>1、报名及获取文件开始时间:2023年11月15日；</p>
              <p>2、报名及获取文件截止时间:2023年11月23日；</p>
              <p>3、开标时间：2023年11月30日09:00（北京时间）；</p>
              <p>4、文件递交截止时间:2023年11月24日18:00前（北京时间）；</p>
              <p>5、开标地点：广东省东莞市南城区宏七路26号中熙时代大厦18楼；</p>
              <p>6、商务联系人及联系方式:卢志华0769-89008900；</p>
              <p>7、技术咨询人及联系方式:林晓烁0769-89008900；</p>
              <p>8、逾期送达或者未送达指定地点的投标文件，询价单位不予受理。</p>
            </div>
          </div>
          <div class="section">
            <h3>六、其他说明：</h3>
            <p>
              本项目经评审有效的投标单位不足3名时为招标失败，招标单位分析招标失败原因，修正招标文件，重新组织招标。
            </p>
            <div class="msg_foot">
              <h6>日期：2023年11月15日</h6>
            </div>
          </div>
        </div>
        <div class="content" v-if="showIndex ==2">
          <h1>《云百科技车联网安全检测评估系统项目硬件采购》项目招标中标公告</h1>
          <p>
            一、广东云百科技有限公司于2023年11月30日对《云百科技车联网安全检测评估系统项目硬件采购》项目进行了开标评比，现对本项目的招标结果进行公示
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">二、采购项目名称:</span>云百科技车联网安全检测评估系统项目硬件采购
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">三、采购方式:</span>公开招标
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">四、中选单位名称:</span>广州衍望科技有限公司
          </p>
        <p>
          <h3 style="text-align: left;font-weight: 700;font-size: 16px;">
              五、评审信息:
            </h3>
            <el-table :data="tableData" border style="width: 100%;margin-top: 10px;">
              <el-table-column prop="p1" label="招标项目" />
              <el-table-column prop="p2" label="中标价格" />
              <el-table-column prop="p3" label="发票类型" />
              <el-table-column prop="p4" label="税率" />
              <el-table-column prop="p5" label="备注" />
            </el-table>
        </p>
    

          <p>
            <span style="font-weight: 700;font-size: 16px;">六、评比日期及地点:</span>2023年11月30日，广东省东莞市南城街道宏七路26号中熙时代大厦18楼1810会议室。
          </p>
          <div class="msg_foot">
              <h6>广东云百科技有限公司</h6>
              <h6>2023年12月1日</h6>
            </div>
        </div>
        <div class="content" v-if="showIndex ==3">
          <h1>《云百科技基于多源信息的异常监测技术与产品项目硬件采购》项目招标中标公告</h1>
          <p>
            一、广东云百科技有限公司于2023年11月30日对《云百科技基于多源信息的异常监测技术与产品项目硬件采购》项目进行了开标评比，现对本项目的招标结果进行公示
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">二、采购项目名称:</span>二、云百科技基于多源信息的异常监测技术与产品项目硬件采购
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">三、采购方式:</span>公开招标
          </p>
          <p>
            <span style="font-weight: 700;font-size: 16px;">四、中选单位名称:</span>广州衍望科技有限公司
          </p>
        <p>
          <h3 style="text-align: left;font-weight: 700;font-size: 16px;">
              五、评审信息:
            </h3>
            <el-table :data="tableData1" border style="width: 100%;margin-top: 10px;">
              <el-table-column prop="p1" label="招标项目" />
              <el-table-column prop="p2" label="中标价格" />
              <el-table-column prop="p3" label="发票类型" />
              <el-table-column prop="p4" label="税率" />
              <el-table-column prop="p5" label="备注" />
            </el-table>
        </p>
    

          <p>
            <span style="font-weight: 700;font-size: 16px;">六、评比日期及地点:</span>2023年11月30日，广东省东莞市南城街道宏七路26号中熙时代大厦18楼1810会议室。
          </p>
          <div class="msg_foot">
              <h6>广东云百科技有限公司</h6>
              <h6>2023年12月1日</h6>
            </div>
        </div>
      </div>
    </div>

    <el-footer>
      <t-foot></t-foot>
    </el-footer>
    <scroll-top></scroll-top>
  </div>
</template>

<script>
import vHead from "../components/head.vue";
import tFoot from "../components/text_footer.vue";
export default {
  components: {
    vHead,
    tFoot,
  },
  data() {
    return {
      showIndex: this.$route.query.id,
      tableData:[{
        p1:'云百科技车联网安全检测评估系统项目硬件采购',
        p2:'270万元（人民币）',
        p3:'专票',
        p4:'13%'
      }

      ],
      tableData1:[{
        p1:'云百科技基于多源信息的异常监测技术与产品项目硬件采购',
        p2:'200万元（人民币）',
        p3:'专票',
        p4:'13%'
      }

      ]
    };
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/home" });
    },

    
  },
};
</script>

<style lang="scss">
.article {
  .base_head {
    height: 80px;
  }
}

.details {
  background: #f7f9fc;
  padding-bottom: 100px;
}

.container {
  width: 1200px;

  .nsv {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #999999;
    height: 60px;

    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
      vertical-align: sub;
      margin-right: 10px;
    }

    .el-breadcrumb {
      margin-top: 3px;
    }
  }

  .content {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
    padding: 100px;

    h1 {
      font-size: 32px;
      font-family: Source Han Sans SC, Source Han Sans SC-Bold;
      font-weight: 700;
      text-align: left;
      color: #4d4d4d;
      line-height: 57px;
    }

    h5 {
      font-size: 17px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 57px;
    }
    .section {
      margin-top: 20px;
      h3 {
        text-align: left;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .msg_foot {
      float: right;
      padding-right: 50px;
      h6 {
        font-size: 18px;
        font-family: Source Han Sans SC, Source Han Sans SC-Bold;
        text-align: right;
        color: #666666;
        line-height: 27px;
      }
    }
    .line {
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
    }

    p,p div {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #4d4d4d;
      line-height: 40px;
    }

    img {
      width: 100%;
      height: 600px;
    }

    h6 {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 40px;
    }
  }
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #000000;
  cursor: text;
}
</style>
